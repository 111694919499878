var WhatsOn = (function($) {
  return {
    container: $('.whats_on__events'),
    loader: $('.whats_on__spinner'),
    date: new Date(),
    previousDate:new Date(),
    nextDate:new Date(),
    init: function() {
      this.setArrows();
      this.fetchEvents((WhatsOn.date.getMonth()+1), WhatsOn.date.getFullYear(), WhatsOn.date.getDate(),false);
    },
    setArrows: function() {

      this.previousDate = new Date(this.previousDate.setDate(this.previousDate.getDate()-1));
      this.nextDate = new Date(this.nextDate.setDate(this.nextDate.getDate()+1));


      $('.whats_on__arrow--left').click(function() {
          WhatsOn.fetchEvents(WhatsOn.previousDate.getMonth()+1,WhatsOn.previousDate.getFullYear(),WhatsOn.previousDate.getDate(), true);
          // WhatsOn.previousDate = new Date(WhatsOn.previousDate.setDate(WhatsOn.previousDate.getDate()-1));
          WhatsOn.setPreviousDate();
      });
      $('.whats_on__arrow--right').click(function() {
          WhatsOn.fetchEvents(next_day[1],next_day[0],next_day[2], false);
      });
    },
    setPreviousDate: function() {
      var p = $('.whats_on__cube').eq(0);
      p = new Date(p.attr('data-year'),p.attr('data-month'),p.attr('data-day'));
      if (p.getTime() > this.previousDate.getTime()) {
        this.previousDate = new Date(this.previousDate.setDate(this.previousDate.getDate()-1));
      }
      this.previousDate = new Date(p.setDate(p.getDate()-1));
    },
    fetchEvents: function(month, year, date, prev) {
      this.clearEvents();
      this.showLoading();
      jQuery.ajax({
        url: ajax.url,
        data: {
            action: 'whatson',
            month: month,
            year: year,
            date: date,
            prev: prev,
        },
        type: 'GET',
        success : function( response ) {
          WhatsOn.container.html(response);
          WhatsOn.hideLoading();
          WhatsOn.setEvents();

        }
      });
    },
    setEvents: function() {
      $('.whats_on__events .whats_on__cube').each(function() {
        var that = this;
        $(this).hover(function() {
          $('.whats_on__cube--hover', that).css('opacity', 1);
          $('span', that).css('opacity', 0);
        }, function() {
          $('.whats_on__cube--hover', that).css('opacity', 0);
          $('span', that).css('opacity', 1);
        });
      });
    },
    clearEvents: function() {
      this.showLoading();
    },
    showLoading: function() {
      this.loader.css('display', 'block');
    },
    hideLoading: function() {
      this.loader.css('display', 'none');
    },

  };
})(jQuery);
