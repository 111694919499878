var Top = (function($) {
  return {
    $elem: $('.to-top'),
    init: function() {
      this.$elem.on('click', function() {
        var e = $('html, body'),
          t = $('#page');

        e.animate({
          scrollTop: t.offset().top
        }, 800);
      });


      window.addEventListener('scroll', function() {
        Top.showTop();
      });
    },
    showTop: function() {
      var wT = window.innerHeight;
      var wPO = window.pageYOffset;

      if (wPO >= wT-200) {
        Top.$elem.css('opacity', 1);
      } else {
        Top.$elem.css('opacity', 0);
      }
    }
  };

})(jQuery);
