var Priory = (function($) {
  return {
    container: $('body'),
    has_touch:'ontouchstart' in document.documentElement,
    init: function() {
      $( '.priory_3d--item').each(function(  ) {
        Priory.hoverEvent($(this), Priory.container);
      });

    },

    hoverEvent: function(el, parent) {
      if(Priory.has_touch) {
        window.addEventListener('devicemotion', deviceMotionHandler, false);
        function deviceMotionHandler(eventData) {
             var accX = Math.round(event.accelerationIncludingGravity.x*10) / 10,
                 accY = Math.round(event.accelerationIncludingGravity.y*10) / 10,
                 xA = -(accX / 10) * el.attr('data-strength'),
                 yA = -(accY / 10) * el.attr('data-strength'),
                 newX = -(xA*2),
                 newY = -(yA*2);
                 el.css({
                   "-webkit-transform": "matrix(" + 1 + ",0,0," + 1 + "," + newX + "," + newY + ")",
                   "-moz-transform": "matrix(" + 1 + ",0,0," + 1 + "," + newX + "," + newY + ")",
                   "-o-transform": "matrix(" + 1 + ",0,0," + 1 + "," + newX + "," + newY + ")",
                   "transform": "matrix(" + 1 + ",0,0," + 1 + "," + newX + "," + newY + ")"
                 });
          }
      } else {
        parent.mouseenter(function(e) {
          el.css({
            "-webkit-transform": "matrix(" + 1 + ",0,0," + 1 + ",0,0)",
            "-moz-transform": "matrix(" + 1 + ",0,0," + 1 + ",0,0)",
            "-o-transform": "matrix(" + 1 + ",0,0," + 1 + ",0,0)",
            "transform": "matrix(" + 1 + ",0,0," + 1 + ",0,0)",
            "-webkit-transition": "-webkit-transform " + '700ms' + " linear",
            "-moz-transition": "-moz-transform " + '700ms' + " linear",
            "-o-transition": "-o-transform " + '700ms' + " linear",
            "transition": "transform " + '700ms' + " linear"
          }).on("transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd", function(){
              // This will signal the mousemove below to execute when the scaling animation stops
              parent.removeClass("ibg-entering")
            });
        }).mousemove(function(e){
          var w = el.outerWidth();
          var h = el.outerHeight();

          var sh = el.attr('data-strength') / h, sw = el.attr('data-strength') / w;

            // This condition prevents transition from causing the movement of the background to lag
            if (!parent.hasClass("ibg-entering") && !parent.hasClass("exiting")) {
              var pageX = e.pageX || e.clientX,
              pageY = e.pageY || e.clientY,
              pageX = (pageX - el.offset().left) - (w / 2),
              pageY = (pageY - el.offset().top) - (h / 2),
              newX = ((sw * pageX)) * - 1,
              newY = ((sh * pageY)) * - 1;
              // Use matrix to move the background from its origin
              // Also, disable transition to prevent lag
              el.css({
                "-webkit-transform": "matrix(" + 1 + ",0,0," + 1 + "," + newX + "," + newY + ")",
                "-moz-transform": "matrix(" + 1 + ",0,0," + 1 + "," + newX + "," + newY + ")",
                "-o-transform": "matrix(" + 1 + ",0,0," + 1 + "," + newX + "," + newY + ")",
                "transform": "matrix(" + 1 + ",0,0," + 1 + "," + newX + "," + newY + ")",
                "-webkit-transition": "none",
                "-moz-transition": "none",
                "-o-transition": "none",
                "transition": "none"
              });
            }
          }).mouseleave(function(e) {
            // Same condition applies as mouseenter. Rescale the background back to its original scale
            parent.addClass("ibg-exiting");
            el.css({
              "-webkit-transform": "matrix(1,0,0,1,0,0)",
              "-moz-transform": "matrix(1,0,0,1,0,0)",
              "-o-transform": "matrix(1,0,0,1,0,0)",
              "transform": "matrix(1,0,0,1,0,0)",
              "-webkit-transition": "-webkit-transform " + '400ms' + " linear",
              "-moz-transition": "-moz-transform " + '400ms' + " linear",
              "-o-transition": "-o-transform " + '400ms' + " linear",
              "transition": "transform " + '400ms' + " linear"
            }).on("transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd", function(){
              parent.removeClass("ibg-exiting");
            });
          });
        }
      }
    };
  })(jQuery);
