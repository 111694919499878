var Nav = (function($) {
  return {
    clickDelayTimer: null,
    clickDelay:300,
    $nav: $('header.banner nav'),
    $logo: $('header.banner .logo'),
    $services: $('header.banner nav.nav-service'),
    $social: $('header.banner .socials'),
    $tel: $('header.banner .tel'),
    init: function() {
      $('.burger-menu__click-region').on('click', function () {
        if(Nav.clickDelayTimer === null) {

          var $burger = $(this);
          // if (Nav.$logo.attr('xlink:href').indexOf('logo-dark') < 0) {
          //   Nav.$logo.attr('xlink:href',Nav.$logo.attr('xlink:href').replace('logo', 'logo-dark'));
          // } else if (Nav.$logo.attr('xlink:href').indexOf('logo-dark')) {
          //   Nav.$logo.attr('xlink:href',Nav.$logo.attr('xlink:href').replace('logo-dark', 'logo'));
          // }

          $burger.toggleClass('active');
          $burger.parent().toggleClass('fixed');
          Nav.$nav.toggleClass('active');
          Nav.$logo.toggleClass('active');
          Nav.$services.toggleClass('deactive');
          Nav.$social.toggleClass('active');
          Nav.$tel.toggleClass('active');

          if(!$burger.hasClass('active')) {
            $burger.addClass('closing');
          }

          Nav.clickDelayTimer = setTimeout(function () {
            $burger.removeClass('closing');
            clearTimeout(Nav.clickDelayTimer);
            Nav.clickDelayTimer = null;
          }, Nav.clickDelay);
        }
      });
    }
  };

})(jQuery);
