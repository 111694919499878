var History = (function($) {
  return {
    pagination: $('.timeline__pagination'),
    history: null,
    top: null,
    pause: false,
    sections: [],
    isIE: null,
    isTablet : navigator.userAgent.match(/Tablet|iPad/i),
    init: function() {
     this.isIE = function(){
        if (window.navigator.userAgent.indexOf("MSIE ") > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {return true; }
        return false;
      }(navigator.userAgent||navigator.vendor||window.opera);

      if (this.isTablet) {
        this.pagination.css('display', 'none');
      }

      $(window).resize(function() {
        History.fixedRelative();
      });

      this.fixedRelative();
      this.setSections();
      this.highlightShow();
      $('a[href*=\\#]', this.pagination).on('click', function(evn) {
        evn.preventDefault();
        if (!History.pause) {
          History.pause = true;
          var e = $('html, body');
          var target = $.attr(this, 'href').substring(1);
          if ($('[data-date="'+target+'"]').length > 0) {
            var top = $('[data-date="'+target+'"]').offset().top - ($('[data-date="'+target+'"]').height()/2) + 100;
            History.disableLinks();
            e.animate({
                scrollTop: top
            }, 1200, function() {
              History.pause = false;
            });
          } else {
            History.pause = false;
          }
        }

      });
    },
    disableLinks: function() {
      $('a[href*=\\#]', this.pagination).each(function() {
        $(this).parent().removeClass('active');
        var svg = $(this).children('svg').children('use');
        var ind = $(this).parent().index()+1;
        if (!this.isIE) {
          var baseurl = svg.attr('xlink:href').replace(/(#[a-z0-9][a-z0-9\-_]*)/ig, '');
          svg.attr('xlink:href',baseurl + '#timeline');
        }
      });
    },
    fixedRelative: function() {
      this.top = this.pagination.offset().top - (window.innerHeight/2) + (this.pagination.height()/2);
      this.history = $('.timeline').outerHeight(true) + $('.timeline').offset().top - $(window).height();
      var top = $('.timeline').outerHeight(true) - $('.timeline').offset().top;

      $(window).scroll(function(){
          History.highlightShow();

          if ($(document).scrollTop() > History.top && $(document).scrollTop() < History.history) {
            History.pagination.addClass('timeline__pagination--fixed');
            History.pagination.removeClass('timeline__pagination--bottom');
          } else if ($(document).scrollTop() > History.history ) {
            History.pagination.removeClass('timeline__pagination--fixed');
            History.pagination.addClass('timeline__pagination--bottom');
          } else {
            History.pagination.removeClass('timeline__pagination--bottom');
            History.pagination.removeClass('timeline__pagination--fixed');

          }
      });
    },
    setActive: function(elem) {
      $(elem).parent().addClass('active');
      var svg = $(elem).children('svg').children('use');
      var ind = $(elem).parent().index()+1;
      if (!this.isIE) {
        var baseurl = svg.attr('xlink:href').replace(/(#[a-z0-9][a-z0-9\-_]*)/ig, '');
        svg.attr('xlink:href',baseurl + '#timeline_active');
      }

    },
    setInactive: function(elem) {
      $(elem).parent().removeClass('active');
      var svg = $(elem).children('svg').children('use');
      var ind = $(elem).parent().index()+1;
      if (!this.isIE) {
        var baseurl = svg.attr('xlink:href').replace(/(#[a-z0-9][a-z0-9\-_]*)/ig, '');
        svg.attr('xlink:href',baseurl + '#timeline');
      }
    },
    setSections: function() {
      var children = $('li',this.pagination).children();
      for (var i=0; i < children.length; i++) {
          History.sections.push($(children[i]).attr('href'));
      }
    },
    highlightShow: function() {
      var windowPos = $(window).scrollTop(); // get the offset of the window from the top of page
      var windowHeight = $(window).height(); // get the height of the window
      var docHeight = $(document).height();

      for (var i=0; i < History.sections.length; i++) {
          var theID = History.sections[i];
          if ($('[data-date="' + theID.substr(1) + '"]').length) {
            var divPos = $('[data-date="' +theID.substr(1) + '"]').offset().top; // get the offset of the div from the top of page
            var divHeight = 0; // get the height of the div in question
            for(var e=0; e < $('[data-date="' + theID.substr(1) + '"]').length; e++) {
              divHeight += $('[data-date="' +theID.substr(1) + '"]').eq(e).height();
            }

            if (windowPos >= (divPos - (divHeight/2)) && windowPos < (divPos + (divHeight/2))) {
                History.setActive($("a[href='" + theID + "']"));
            } else {
                History.setInactive($("a[href='" + theID + "']"));
            }
        }
      }

      if (windowPos < $('[data-date="'+History.sections[0].substr(1)+ '"]').offset().top) {
          if (!$('li:first-child', this.pagination).hasClass("active")) {
                navActiveCurrent = $('li:first-child', this.pagination).children('a').attr("href");
                $("a[href='" + navActiveCurrent + "']").parent().removeClass("active");
                History.setActive($('li:first-child', this.pagination).children('a'));
            }
        }
        if(windowPos + windowHeight == docHeight) {
            if (!$("header nav li:last-child a").hasClass("active")) {
                navActiveCurrent = $(".active").attr("href");
                $("a[href='" + navActiveCurrent + "']").removeClass("active");
                $("header nav li:last-child a").addClass("active");
            }
        }
    }
  };
})(jQuery);

Initialize.loadPage('page_template_template_history', History);
