var Contact = (function($) {
  return {
    map: null,
    container: $('.map'),
    markers: null,
    init: function() {
      Form.init();
      Contact.container.each(function(){
        // create map
        Contact.map = Contact.newMap( $(this) );
      });

    },
    newMap: function($el) {
      Contact.markers = $el.parent().find('.marker');
      // vars
      var args = {
        zoom        : 16,
        center      : new google.maps.LatLng(0, 0),
        mapTypeId   : google.maps.MapTypeId.ROADMAP,
        scrollwheel: false,
        styles : [{"featureType":"administrative","elementType":"all","stylers":[{"visibility":"on"}]},{"featureType":"water","elementType":"all","stylers":[{"visibility":"simplified"},{"hue":"#e9ebed"},{"saturation":-78},{"lightness":67}]},{"featureType":"landscape","elementType":"all","stylers":[{"visibility":"simplified"},{"hue":"#ffffff"},{"saturation":-100},{"lightness":100}]},{"featureType":"road","elementType":"geometry","stylers":[{"visibility":"simplified"},{"hue":"#bbc0c4"},{"saturation":-93},{"lightness":31}]},{"featureType":"poi","elementType":"all","stylers":[{"visibility":"off"},{"hue":"#ffffff"},{"saturation":-100},{"lightness":100}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"visibility":"simplified"},{"hue":"#e9ebed"},{"saturation":-90},{"lightness":-8}]},{"featureType":"transit","elementType":"all","stylers":[{"visibility":"on"},{"hue":"#e9ebed"},{"saturation":10},{"lightness":69}]},{"featureType":"administrative.locality","elementType":"all","stylers":[{"visibility":"on"},{"hue":"#2c2e33"},{"saturation":7},{"lightness":19}]},{"featureType":"road","elementType":"labels","stylers":[{"visibility":"on"},{"hue":"#bbc0c4"},{"saturation":-93},{"lightness":31}]},{"featureType":"road.arterial","elementType":"labels","stylers":[{"visibility":"simplified"},{"hue":"#bbc0c4"},{"saturation":-93},{"lightness":-2}]}]
      };


      // create map
      Contact.map = new google.maps.Map( $el[0], args);


      // add a markers reference
      Contact.map.markers = [];


      // add markers
      Contact.markers.each(function(){

        Contact.addMarker( $(this));

      });


      // center map
      Contact.centerMap( );
      google.maps.event.addDomListener(window, "resize", function() {
        Contact.centerMap( );
      });


      // return
      return Contact.map;
    },
    addMarker: function( $marker ) {

      // var
      var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );

       var image = new google.maps.MarkerImage(
        '/wp-content/themes/cartmelpriory/dist/images/icon.png',
        null, // size
        null, // origin
        new google.maps.Point(20, 66), // anchor (move to center of marker)
        new google.maps.Size(58, 76) // scaled size (required for Retina display icon)
      );

      // create marker
      var marker = new google.maps.Marker({
        position    : latlng,
          map         : Contact.map,
          icon: image,
          flat: true,
          optimized: false,
          title: '',
          visible: true
        });

      // add to array
      Contact.map.markers.push( marker );
      // if marker contains HTML, add it to an infoWindow

      if( $marker.attr('data-address'))
      {
          // create info window
          var infowindow = new google.maps.InfoWindow({
            content     : Contact.formatAddress($marker.attr('data-address'))
          });

          // show info window when marker is clicked
          google.maps.event.addListener(marker, 'click', function() {

            window.open('https://www.google.co.uk/maps/place/Cartmel+Priory/');

          });
        }

      },
      centerMap: function( ) {

      // vars
      var bounds = new google.maps.LatLngBounds();

      // loop through all markers and create bounds
      $.each( Contact.map.markers, function( i, marker ){

        var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );

        bounds.extend( latlng );

      });

      // only 1 marker?
      if( Contact.map.markers.length === 1 )
      {
          // set center of map
          Contact.map.setCenter( bounds.getCenter() );
          Contact.map.setZoom( 16 );
        }
        else
        {
          // fit to bounds
          Contact.map.fitBounds( bounds );
        }

      },
      formatAddress: function(address) {
        var output = '';
        address = address.split(',');

        for(i=0;i<address.length;i++) {
          output += address[i]+'<br/>';
        }

        return output.substring(0, output.length - 5);
      }
    };
  })(jQuery);

  Initialize.loadPage('visit_us', Contact);
