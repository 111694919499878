var Home = (function($) {
  return {
    pagination: $('.cross_sell__pagination'),
    cross_sell: null,
    upsell: $('.up_sell article:not(.nolink)'),
    priority_links: $('.cross_sell article'),
    top: null,
    pause: false,
    sections: [],
    isIE: null,
    isTablet : navigator.userAgent.match(/Tablet|iPad/i),
    init: function() {
      WhatsOn.init();
      this.isIE = function(){
          if (window.navigator.userAgent.indexOf("MSIE ") > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {return true; }
          return false;
        }(navigator.userAgent||navigator.vendor||window.opera);


      if (this.isTablet) {
        this.pagination.css('display', 'none');
      }

      $(window).resize(function() {
        $(window).unbind("scroll");
        Home.fixedRelative();
      });
      this.fixedRelative();
      this.setSections();
      this.upSell();
      this.priorityLinks();
      this.highlightShow();
      $('a[href*=\\#]', this.pagination).on('click', function(evn) {
        evn.preventDefault();
        if (!Home.pause) {
          Home.pause = true;
          var e = $('html, body');
          var target = $.attr(this, 'href');
          var top = $(target).offset().top - ($(target).height()/2) + 100;
          Home.disableLinks();
          e.animate({
              scrollTop: top
          }, 1200, function() {
            Home.pause = false;
          });
        }

      });
    },
    disableLinks: function() {
      $('a[href*=\\#]', this.pagination).each(function() {
        $(this).parent().removeClass('active');
        var svg = $(this).children('svg').children('use');
        var ind = $(this).parent().index()+1;
        if (!this.isIE) {
          var baseurl = svg.attr('xlink:href').replace(/(#[a-z0-9][a-z0-9\-_]*)/ig, '');
          svg.attr('xlink:href',baseurl + '#pagination_' + ind);
        }
      });
    },
    fixedRelative: function() {
      this.top = this.pagination.offset().top - (window.innerHeight/2) + (this.pagination.height()/2);
      this.cross_sell = $('.cross_sell').outerHeight(true) + $('.cross_sell').offset().top - $(window).height();
      var top = $('.cross_sell').outerHeight(true) - $('.cross_sell').offset().top;

      $(window).bind('scroll',function(){
          Home.highlightShow();
          if ($(document).scrollTop() > Home.top && $(document).scrollTop() < Home.cross_sell) {
            Home.pagination.addClass('cross_sell__pagination--fixed');
            Home.pagination.removeClass('cross_sell__pagination--bottom');
          } else if ($(document).scrollTop() > Home.cross_sell ) {
            Home.pagination.removeClass('cross_sell__pagination--fixed');
            Home.pagination.addClass('cross_sell__pagination--bottom');
          } else {
            Home.pagination.removeClass('cross_sell__pagination--bottom');
            Home.pagination.removeClass('cross_sell__pagination--fixed');

          }
      });
    },
    setActive: function(elem) {
      $(elem).parent().addClass('active');
      var svg = $(elem).children('svg').children('use');
      var ind = $(elem).parent().index()+1;
      if (!this.isIE) {
        var baseurl = svg.attr('xlink:href').replace(/(#[a-z0-9][a-z0-9\-_]*)/ig, '');
        svg.attr('xlink:href',baseurl + '#pagination_' + ind + '_active');
      }


    },
    setInactive: function(elem) {
      $(elem).parent().removeClass('active');
      var svg = $(elem).children('svg').children('use');
      var ind = $(elem).parent().index()+1;
      if (!this.isIE) {
        var baseurl = svg.attr('xlink:href').replace(/(#[a-z0-9][a-z0-9\-_]*)/ig, '');
        svg.attr('xlink:href',baseurl + '#pagination_' + ind);
      }
    },
    setSections: function() {
      var children = $('li',this.pagination).children();
      for (var i=0; i < children.length; i++) {
          Home.sections.push($(children[i]).attr('href'));
      }
    },
    highlightShow: function() {
      var windowPos = $(window).scrollTop(); // get the offset of the window from the top of page
      var windowHeight = $(window).height(); // get the height of the window
      var docHeight = $(document).height();

      for (var i=0; i < Home.sections.length; i++) {
          var theID = Home.sections[i];
          if ($(theID).length) {
            var divPos = $(theID).offset().top; // get the offset of the div from the top of page
            var divHeight = $(theID).height(); // get the height of the div in question
            if (windowPos >= (divPos - (divHeight/2)) && windowPos < (divPos + (divHeight/2))) {
                Home.setActive($("a[href='" + theID + "']"));
            } else {
                Home.setInactive($("a[href='" + theID + "']"));
            }
        }
      }

      if (windowPos < $(Home.sections[0]).offset().top) {
          if (!$('li:first-child', this.pagination).hasClass("active")) {
                navActiveCurrent = $('li:first-child', this.pagination).children('a').attr("href");
                $("a[href='" + navActiveCurrent + "']").parent().removeClass("active");
                Home.setActive($('li:first-child', this.pagination).children('a'));
            }
        }
        if(windowPos + windowHeight == docHeight) {
            if (!$("header nav li:last-child a").hasClass("active")) {
                navActiveCurrent = $(".active").attr("href");
                $("a[href='" + navActiveCurrent + "']").removeClass("active");
                $("header nav li:last-child a").addClass("active");
            }
        }
    },
    upSell: function() {
      this.upsell.click(function() {
        alink = $(this).children('div').children('div').children('.table').children('.table-cell').children('a');
        page = alink.attr('href');
        window.location = page;
      });
    },
    priorityLinks: function() {
      this.priority_links.click(function() {
        alink = $(this).children('div').children('.table').children('.table-cell').children('p').children('a');
        page = alink.attr('href');
        window.location = page;
      });
    }
  };
})(jQuery);

Initialize.loadPage('home', Home);
