var RandomSVGS = (function($) {
  return {
    container:null,
    s:null,
    holder:null,
    pattern1:null,
    pattern2:null,
    patterns:null,
    xs:[],
    ys:[],
    count: 8,
    colours:["#d8d6ce", "#11448c", "#f2052d", "#f4af2d", "#e8e2c6"],
    init: function() {
      this.container = $(".random_pattern");
      if (this.container.length) {
        this.s = Snap(".random_pattern__pattern");
        this.holder = Snap.select("#random_pattern__pats").group();
        if (this.container.height() < 1000) {
          this.count = 3;
        }
        for (i = 0; i < this.count; i++) {
          RandomSVGS.makePatterns();
        }
      }

    },
    random: function(minimum, maximum, precision) {
        minimum = minimum === undefined ? 0 : minimum;
        maximum = maximum === undefined ? 9007199254740992 : maximum;
        precision = precision === undefined ? 0 : precision;

        var random = Math.random() * (maximum - minimum) + minimum;

        return random.toFixed(precision);
    },
    getX:function() {
      var x = 20 + Math.random() * (RandomSVGS.container.outerWidth() - 200);
      var range = 70;
      for (i = 0; i < RandomSVGS.xs.length; i++) {
        if (
          parseInt(x.toFixed(0)) >= parseInt((RandomSVGS.xs[i] - range).toFixed(0)) &&
          parseInt(x.toFixed(0)) <= parseInt((RandomSVGS.xs[i] + range).toFixed(0))
          ) {
          RandomSVGS.getX();

        break;
      }
    }

    RandomSVGS.xs.push(x);
    return x;
  },
  getY:function() {
    var y = 20 + Math.random() * (this.container.outerHeight() - 200);
    var range = 70;
    for (i = 0; i < RandomSVGS.ys.length; i++) {
      if (
        parseInt(y.toFixed(0)) >= parseInt((RandomSVGS.ys[i] - range).toFixed(0)) &&
        parseInt(y.toFixed(0)) <= parseInt((RandomSVGS.ys[i] + range).toFixed(0))
        ) {
        RandomSVGS.getY();
      break;
    }
  }
  RandomSVGS.ys.push(y);
  return y;
},
makePatterns:function() {
  var scale = 0.6;
  var whichPattern = parseInt(RandomSVGS.random(0, 2, 0));
  var color = this.colours[parseInt(RandomSVGS.random(0, this.colours.length - 1, 0))];
  var newPattern;

  var x = this.getX();
  var y = this.getY();

  switch (whichPattern) {
    case 0:
    newPattern = RandomSVGS.holder
    .group(
      RandomSVGS.holder
      .path(
        "M57,40c-3.7-2.6-7.6-3.6-11-3.8c0.9-2.3,1.6-4.9,1.6-7.9c0-12.5-10.8-18.7-10.8-18.7S26,15.9,26,28.3,c0,3,0.6,5.6,1.6,7.9c-3.4,0.2-7.1,1.2-10.8,3.8C6.7,47.2,7.9,59.6,7.9,59.6s11.3,5.2,21.4-2.1c4.1-2.9,6.4-6.7,7.6-10.2,c1.2,3.5,3.5,7.2,7.6,10.2c10.1,7.2,21.4,2.1,21.4,2.1S67.1,47.2,57,40z"
        )
      .attr({
        fill: "none",
        stroke: color,
        strokeWidth: 1.71,
        strokeMiterlimit: 10
      }),
      RandomSVGS.holder
      .path(
        "M61.5,35c-2.6-2-5.4-3.3-8.1-4.1c0.1-1.1,0.2-2.3,0.2-3.5c0-17.6-17-26.4-17-26.4s-17,8.8-17,26.4,c0,1.2,0.1,2.5,0.2,3.6c-2.6,0.8-5.1,2.1-7.6,3.9c-14.1,10.5-11,29.4-11,29.4s17.2,8.4,31.3-2.2c1.7-1.2,3.1-2.6,4.3-4,c1.2,1.4,2.6,2.8,4.3,4c14.1,10.5,31.3,2.2,31.3,2.2S75.6,45.5,61.5,35z"
        )
      .attr({
        fill: "none",
        stroke: color,
        strokeWidth: 1.71,
        strokeMiterlimit: 10
      })
      )
    .transform("translate(" + x + " " + y + "); scale(" + scale + ")");
    break;
    case 1:
    newPattern = RandomSVGS.holder
    .group(
      RandomSVGS.holder
      .path(
        "M80.3,41.5c0-10.7-7.8-19.6-18.1-21.3c-1.3-10.7-10.4-19-21.5-19c-11,0-20.2,8.3-21.5,19,C9,21.8,1.2,30.7,1.2,41.5c0,10.8,8,19.8,18.3,21.4c2,9.9,10.7,17.4,21.2,17.4c10.5,0,19.2-7.5,21.2-17.4,C72.3,61.3,80.3,52.3,80.3,41.5z M58.6,25.8c-0.9,0-1.7,0.1-2.6,0.2c0.2-1,0.3-2.1,0.3-3.2c0-8.7-7-15.7-15.7-15.7S25,14.1,25,22.8,c0,1.1,0.1,2.2,0.3,3.2c-0.8-0.1-1.7-0.2-2.6-0.2c-8.7,0-15.7,7-15.7,15.7c0,8.7,7,15.7,15.7,15.7c0.8,0,1.6-0.1,2.3-0.2,c-0.1,0.5-0.1,1.1-0.1,1.6c0,8.7,7,15.7,15.7,15.7s15.7-7,15.7-15.7c0-0.6,0-1.1-0.1-1.6c0.8,0.1,1.5,0.2,2.3,0.2,c8.7,0,15.7-7,15.7-15.7C74.3,32.8,67.3,25.8,58.6,25.8z"
        )
      .attr({
        fill: "none",
        stroke: color,
        strokeWidth: 1.71,
        strokeMiterlimit: 10
      })
      )
    .transform("translate(" + x + " " + y + "); scale(" + scale + ")");
    break;
    case 2:
    scale = 1;
    newPattern = RandomSVGS.holder
    .group(
      RandomSVGS.holder
      .path(
        "m25.7,51.4l-12.1,-12.1l12.1,-12.1l12.1,12.1l-12.1,12.1zm-9.7,-12.1l9.7,9.7l9.7,-9.7l-9.7,-9.7l-9.7,9.7z"
        )
      .attr({
        fill: color
      }),
      RandomSVGS.holder
      .path(
        "m25.7,45.9l-6.7,-6.6l6.7,-6.7l6.7,6.7l-6.7,6.6zm-4.6,-6.6l4.6,4.6l4.6,-4.6l-4.6,-4.6l-4.6,4.6z"
        )
      .attr({
        fill: color
      }),
      RandomSVGS.holder
      .path(
        "m25.7,24.4l-12.1,-12.1l12.1,-12.1l12.1,12.1l-12.1,12.1zm-9.7,-12.1l9.7,9.7l9.7,-9.7l-9.7,-9.7l-9.7,9.7z"
        )
      .attr({
        fill: color
      }),
      RandomSVGS.holder
      .path(
        "m25.7,19l-6.7,-6.7l6.7,-6.7l6.7,6.7l-6.7,6.7zm-4.6,-6.7l4.6,4.6l4.6,-4.6l-4.6,-4.6l-4.6,4.6z"
        )
      .attr({
        fill: color
      }),
      RandomSVGS.holder
      .path(
        "m39.2,37.9l-12.1,-12.1l12.1,-12.1l12.1,12.1l-12.1,12.1zm-9.7,-12.1l9.7,9.7l9.7,-9.7l-9.7,-9.7l-9.7,9.7z"
        )
      .attr({
        fill: color
      }),
      RandomSVGS.holder
      .path(
        "m39.2,32.5l-6.7,-6.7l6.7,-6.7l6.7,6.7l-6.7,6.7zm-4.6,-6.7l4.6,4.6l4.6,-4.6l-4.6,-4.6l-4.6,4.6z"
        )
      .attr({
        fill: color
      }),
      RandomSVGS.holder
      .path(
        "m12.3,37.9l-12.1,-12.1l12.1,-12.1l12.1,12.1l-12.1,12.1zm-9.7,-12.1l9.7,9.7l9.7,-9.7l-9.7,-9.7l-9.7,9.7z"
        )
      .attr({
        fill: color
      }),
      RandomSVGS.holder
      .path(
        "m12.3,32.5l-6.7,-6.7l6.7,-6.7l6.7,6.7l-6.7,6.7zm-4.6,-6.7l4.6,4.6l4.6,-4.6l-4.6,-4.6l-4.6,4.6z"
        )
      .attr({
        fill: color
      })
      )
    .transform("translate(" + x + " " + y + "); scale(" + scale + ")");
    break;
  }
}

};

})(jQuery);
