var Events = (function($) {
  return {
    init: function() {
      Calendar.init();

      $('.calendar__featuredwrap').slick({
          byRow: true,
          dots: true,
          arrows:true,
          infinite: true,
          autoplay: true,
          speed: 1300,
          slidesToShow: 2,
          slidesToScroll: 2,
          lazyLoad: 'ondemand',
          prevArrow: '<span class="arrow--left"><span class="icon-prev" aria-hidden="true"><svg role="img" xml:space="preserve"><title>Previous</title><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/wp-content/themes/cartmelpriory/dist/images/sprite.svg#arrow_left"></use></svg></span><span class="sr-only">Previous</span></span>',
          nextArrow: '<span class="arrow--right"><span class="icon-next" aria-hidden="true"><svg role="img" xml:space="preserve"><title>Next</title><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/wp-content/themes/cartmelpriory/dist/images/sprite.svg#arrow_right"></use></svg></span><span class="sr-only">Next</span></span>',
          responsive: [{
            breakpoint: 800,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows:false,
              dots: true,
              adaptiveHeight: false
            }
          }]
        });
    }
  };
})(jQuery);

Initialize.loadPage('post_type_archive_event', Events);
