var SubMenu = (function($) {
  return {
    is_visible: false,
    $body: $('body'),
    menu: $('.sub_menu').hide(),
    hide_timer: null,
    show_timer: null,
    anim_duration: 300,
    mousestate: null,
    hide_timeout: null,
    selected: null,
    w_width: null,
    hover: null,
    menu_left: null,
    menu_top: null,
    button_height: null,
    prevent_initial_mouseenter: true,
    init: function() {
        SubMenu.$body.on('mousemove.togglemenu', SubMenu.unlockMenuTriggerMouseenter);

            $('.sub_menu--trigger').on('mouseenter', function(event){
                if (SubMenu.prevent_initial_mouseenter || $(window).width() < 940 || $(window).height() < 650 ) {
                    return;
                }
                SubMenu.resetActive();
                SubMenu.selected = SubMenu.getSlug($(this).find('a').attr('href'));
                SubMenu.hover = $(this);
                SubMenu.openMenu();
                SubMenu.$body.addClass('menu__open');
                SubMenu.menu_left = $('.menu-the-priory-container').offset().left;
                //SubMenu.menu_top = $(this).offset().top + 10;
                //SubMenu.button_height = $(this).height();
                //SubMenu.menu.find('.sub_menu__nav[data-nav="'+SubMenu.selected+'"]').css({'left':Math.round(SubMenu.menu_left ), 'top' :Math.round(SubMenu.menu_top + SubMenu.button_height)});
                $(this).siblings('li').not('.sub_menu--trigger').on('mouseenter.togglemenu', SubMenu.autoCloseMenu);
                SubMenu.w_width = $(window).width();

                //var disable_point = getDisablePoint();
                SubMenu.mousestate = 'inside'; //( event.pageX < w_width / 2 ) ? 'outside' : 'inside';

                SubMenu.$body
                    .on('mousemove.togglemenu', SubMenu.updateMenuMousePosition)
                    .on('click.togglemenu', SubMenu.checkOutsideClick);

                event.preventDefault();

            });


    },
    resetActive: function() {
        $('.sub_menu--trigger').removeClass('active');

    },
    closeOnEscape: function(event) {
        var keyCode = event.keyCode || event.which;
        if ( keyCode === 27 ) {
            SubMenu.closeMenu();
        }
    },

    closeMenu: function() {
        SubMenu.$body.removeClass('menu__open');
        if (SubMenu.show_timer) {
            clearTimeout(SubMenu.show_timer);
        }

        SubMenu.menu.removeClass('sub_menu--visible');
        SubMenu.hover.removeClass('active');
        SubMenu.hide_timer = setTimeout(function(){
            SubMenu.menu.hide();
            // $htmlbody.css({'overflow':''});
        }, SubMenu.anim_duration);

        SubMenu.menu.off('click', SubMenu.detectClickOutside);
        SubMenu.$body.off('keyup', SubMenu.closeOnEscape);

        SubMenu.is_visible = false;

    },
    openMenu: function() {

        if (SubMenu.hide_timer) {
            clearTimeout(SubMenu.hide_timer);
        }

        // $htmlbody.css({'overflow':'hidden'});

        SubMenu.menu.show();
        SubMenu.hover.addClass('active');
        SubMenu.menu.find('.sub_menu__nav').hide();
        SubMenu.menu.find('.sub_menu__nav[data-nav="'+SubMenu.selected+'"]').show();

        SubMenu.show_timer = setTimeout(function(){
            SubMenu.menu.addClass('sub_menu--visible');
            SubMenu.menu.on('click', SubMenu.detectClickOutside);
            SubMenu.$body.on('keyup', SubMenu.closeOnEscape);
        }, 16);

        SubMenu.is_visible = true;

    },

    detectClickOutside: function(event) {
        if ($(event.target).is('.sub_menu__bg')) {
            SubMenu.closeMenu();
        }
    },

    getDisablePoint: function() {
        return {
            'x' : ( SubMenu.menu_left ) - 100,
            'y' : SubMenu.menu.find('.menu-the-priory-right-container').offset().top + SubMenu.menu.find('.menu-the-priory-right-container').height() + 100
        };
    },
    // remove on click outside for stressed out people
    checkOutsideClick: function(event){
        var disable_point = SubMenu.getDisablePoint();

        if ( event.pageX < disable_point.x || event.pageY > disable_point.y ) {
            SubMenu.autoCloseMenu();
        }
    },

    autoCloseMenu: function() {

        if (SubMenu.hide_timeout) {
            clearTimeout(SubMenu.hide_timeout);
        }
        SubMenu.mousestate = undefined;
        SubMenu.$body.off('mousemove.togglemenu', SubMenu.updateMenuMousePosition);
        SubMenu.$body.off('click.togglemenu', SubMenu.checkOutsideClick);
        $('.sub_menu--trigger').siblings('li').off('mouseenter.togglemenu', SubMenu.autoCloseMenu);
        SubMenu.closeMenu();
    },

    updateMenuMousePosition: function(event){

        var disable_point = SubMenu.getDisablePoint();

        if ( ( event.pageX < disable_point.x || event.pageY > disable_point.y ) && SubMenu.mousestate === 'inside' ) {

            SubMenu.hide_timeout = setTimeout(SubMenu.autoCloseMenu, 500);
            SubMenu.mousestate = 'outside';

        }else if ( ( event.pageX >= disable_point.x && event.pageY <= disable_point.y ) && SubMenu.mousestate === 'outside' ) {

            if (SubMenu.hide_timeout) {
                clearTimeout(SubMenu.hide_timeout);
            }

            SubMenu.mousestate = 'inside';

        }

    },

    // prevent menu from popping up when moving away from menu item after page load



    unlockMenuTriggerMouseenter: function(event) {

        // detects when mouse moves away from button
        if ( !$(event.target).closest('.sub_menu--trigger').length ) {
            SubMenu.prevent_initial_mouseenter = false;
            SubMenu.$body.off('mousemove.togglemenu', SubMenu.unlockMenuTriggerMouseenter);
        }
    },

    getSlug: function(url) {
        var slug = url.split('/');
        return slug[slug.length - 2];
    }
  };

})(jQuery);
