var Form = (function($) {
  return {
    $form: $('.wpcf7'),
    $select: $('.wpcf7-select'),
    init: function() {
      term = this.getParameterByName('type');
      //find select
      if (this.$select.length && this.doesOptionexist(term)) {
        this.$select.val(term);
      }
    },
    doesOptionexist: function(term) {
      i = 0;
      $('option', this.$select).each(function() {

        if ($(this).val() === term) { i++; }
      });
      return i;
    },
    getParameterByName: function(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }
  };

})(jQuery);
